



















import { defineComponent, getCurrentInstance, Ref } from '@vue/composition-api'
import { ApolloError, NetworkStatus } from 'apollo-client'

import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import PageView from '@/components/templates/h/yosan-haibun/item-conf/PageView.vue'
import {
  ItemConfExtractCondition,
  ColumnLabel,
  ItemConfOutputCondition,
} from '@/types/entities'
// import { TemplateFile, ResourceType } from '@/types/generated/graphql'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPItemConfsCountDocument,
} from '@/types/generated/graphql'
import {
  OUTPUT_COND_DELETE,
  OUTPUT_COND_ITEM,
  OUTPUT_COND_STORE,
  OUTPUT_EXTRACT_TARGET_DEFAULT,
  SELECT_MAX_COUNT,
} from '@/utils/constant'
import { EXPORT_FILENAME } from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { addDayYMD } from '@/utils/mixin'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast from '@/utils/toast'
const DEFAULT_OUTPUT_CONST = {
  extractTarget: OUTPUT_EXTRACT_TARGET_DEFAULT, // 出力条件（抽出対象）はデフォルト値を設定できるようにしていないため、定数を用意した
  storeOutput: [OUTPUT_COND_STORE.all],
  itemOutput: [OUTPUT_COND_ITEM.tanpin],
}
const DEFAULT_EXTRACT_CONST = {
  validData: [addDayYMD(3), addDayYMD(3)],
}
export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      defaultDateValue: DEFAULT_EXTRACT_CONST.validData,
      resourceType: ResourceType.UiPItemConf,
      templateFile: TemplateFile.UiPItemConf_01,
      extractCondition: {
        validData: DEFAULT_EXTRACT_CONST.validData,
        chubunrui: [],
        shobunrui: [],
        class: [],
        category: [],
        tanpin: [],
      },
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Store',
          displayItems: DEFAULT_OUTPUT_CONST.storeOutput,
          defaultSelected: DEFAULT_OUTPUT_CONST.storeOutput,
          disabledItems: DEFAULT_OUTPUT_CONST.storeOutput,
        },
        {
          outputCondType: 'Item',
          displayItems: DEFAULT_OUTPUT_CONST.itemOutput,
          defaultSelected: DEFAULT_OUTPUT_CONST.itemOutput,
          disabledItems: DEFAULT_OUTPUT_CONST.itemOutput,
        },
      ],
      totalCount: null,
      exportSpinner: false,
    }
  },
  methods: {
    changeExtractCondition(cond: ItemConfExtractCondition) {
      console.log('changeExtractCondition', cond)
      this.extractCondition = cond
      this.requestCount()
    },

    changeOutputCondition(cond: ItemConfOutputCondition) {
      console.log('changeConditionCondition', cond)
      this.outputCondition = cond
      this.requestCount()
    },
    async requestCount() {
      this.exportSpinner = true
      this.totalCount = null
      if (this.checkRequired(false)) {
        try {
          const result = await this.$apollo.query({
            query: VUiPItemConfsCountDocument,
            variables: { where: this._createWhere() },
          })
          const data = result.data.vUiPItemConfs.totalCount
          this.totalCount = data.toString()
          this.exportSpinner = false
        } catch (e) {
          console.error(e)
          this.exportSpinner = false
          this.totalCount = '-'
        }
      } else {
        this.exportSpinner = false
        this.totalCount = '-'
      }
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({
        files: files,
        resourceType: this.resourceType,
        optionXlsx: { skipRows: 3 },
      })
    },
    /*********************************
     * 以下はエクスポートリクエスト用の処理
     *********************************/

    //　入力チェック
    checkRequired(showToast: boolean) {
      // 入力チェック開始
      let errMsg = []
      if (
        !this.extractCondition.validData[0] ||
        !this.extractCondition.validData[1] ||
        (this.extractCondition.chubunrui.length == 0 &&
          this.extractCondition.shobunrui.length == 0 &&
          this.extractCondition.class.length == 0 &&
          this.extractCondition.category.length == 0 &&
          this.extractCondition.tanpin.length == 0)
      ) {
        errMsg.push({
          message: this.$i18n.t('error.requiredItemNotInput'),
        })
      }
      if (this.extractCondition.chubunruiCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.chubunrui'),
            selected: this.extractCondition.chubunruiCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.shobunruiCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.shobunrui'),
            selected: this.extractCondition.shobunruiCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.classCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.class'),
            selected: this.extractCondition.classCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.categoryCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.category'),
            selected: this.extractCondition.categoryCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.tanpinCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.tanpin'),
            selected: this.extractCondition.tanpinCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (errMsg.length) {
        if (showToast) {
          for (const msg of errMsg) {
            Toast.error(this, msg)
          }
        }
        // 入力チェックに引っかかった場合はエクスポートの要求を出さない
        return false
      }
      return true
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      if (this.checkRequired(true)) {
        // this._create_query_string()
        this.helper.export({
          query: this._create_query_string(),
          jmespathQuery:
            'data.vUiPItemConfs.edges[].node | map(&merge(`{"__update": null}`, @), @)',
          templateFile: this.templateFile,
          filenameExport: EXPORT_FILENAME.item_conf,
          columnLabelMapping: this._create_column_label_mapping(),
        })
      }
    },
    _create_query_string() {
      const where = this._createWhere()
      const rowQuery = `
query {
  vUiPItemConfs(
    where: ${this.helper.generateQueryWhereString(where)}
    orderBy: ${this._createOrderByString()}
  ) {
    edges {
      node {
        inactiveFlg
        itemCd
        item{
          saleItemName
          category1
          category2
          category3
          uiMCategory1{
            categoryName
          }
          uiMCategory2{
            categoryName
          }
          uiMCategory3{
            categoryName
          }
          uiMRefStoreitemSaleItem{
            vendorCd
          }
          quantity
        }
        saleAmount1w
        discardAmount1w
        discardRate1w
        saleNum1wBefore
        saleNum2wBefore
        saleNum3wBefore
        saleNum3w
        profitAmount3w
        profitRate3w
        price
        cost
        profitRate
        moq
        varietyGroupId
        varietyGroupIdName
        absoluteOrderFlg
        varietyPriority
        r1StoreinDowPattern{
          storeinDowPatternId
        }
        r2StoreinDowPattern{
          storeinDowPatternId
        }
        r3StoreinDowPattern{
          storeinDowPatternId
        }
        r4StoreinDowPattern{
          storeinDowPatternId
        }
        r5StoreinDowPattern{
          storeinDowPatternId
        }
        r6StoreinDowPattern{
          storeinDowPatternId
        }
        configuredFlg
        lastModifiedDatetime
        lastModifiedUserName
      }
    }
  }
}

`
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    _createWhere() {
      // 単品が選択されている際、その他の絞り込み条件は無視する
      let wkExtractCondition = this.extractCondition
      if (this.extractCondition.tanpin.length) {
        const newExtractCondition = {
          validData: [],
          chubunrui: [],
          shobunrui: [],
          class: [],
          category: [],
          tanpin: this.extractCondition.tanpin,
        }
        wkExtractCondition = newExtractCondition
      }
      // 抽出条件
      const ITEM_SEARCH_CONDITION: Where[] = [
        // 商品基準日
        {
          field: ['saleItems/uiMShelfPatterns/startDate'],
          op: WhereOp.Le,
          value: [''],
        },
        {
          field: ['saleItems/uiMShelfPatterns/endDate'],
          op: WhereOp.Ge,
          value: [''],
        },
        {
          field: ['item/category0'],
          op: WhereOp.In,
          value: [''],
        },
        { field: ['item/category1'], op: WhereOp.In, value: [''] },
        { field: ['item/category2'], op: WhereOp.In, value: [''] },
        { field: ['item/category3'], op: WhereOp.In, value: [''] },
        { field: ['itemCd'], op: WhereOp.In, value: [''] },
        { field: ['configuredFlg'], op: WhereOp.In, value: [''] },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))
      // 商品基準日
      setWhereValue(
        where,
        'saleItems/uiMShelfPatterns/startDate',
        wkExtractCondition.validData[1]
      )
      setWhereValue(
        where,
        'saleItems/uiMShelfPatterns/endDate',
        wkExtractCondition.validData[0]
      )

      setWhereValue(where, 'item/category0', wkExtractCondition.chubunrui)
      setWhereValue(where, 'item/category1', wkExtractCondition.shobunrui)
      setWhereValue(where, 'item/category2', wkExtractCondition.class)
      setWhereValue(where, 'item/category3', wkExtractCondition.category)
      setWhereValue(where, 'itemCd', wkExtractCondition.tanpin)

      setWhereValue(where, 'configuredFlg', this.outputCondition.extractTarget)
      console.log({ where })
      return where
    },
    _createOrderByString() {
      const orderBy: OrderBy[] = [
        {
          field: 'item/category1',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'item/category2',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'item/category3',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'varietyGroupId',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsFirst,
        },
        {
          field: 'varietyPriority',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'saleAmount_1w',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'itemCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsFirst,
        },
      ]
      console.log({ orderBy })
      return this.helper.generateQueryOrderByString(orderBy)
    },
    _create_column_label_mapping() {
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: '__update',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'itemCd',
          to: '',
        },
        {
          from: 'item.saleItemName',
          to: '',
        },
        {
          from: 'item.category1',
          to: '',
        },
        {
          from: 'item.uiMCategory1.categoryName',
          to: '',
        },
        {
          from: 'item.category2',
          to: '',
        },
        {
          from: 'item.uiMCategory2.categoryName',
          to: '',
        },
        {
          from: 'item.category3',
          to: '',
        },
        {
          from: 'item.uiMCategory3.categoryName',
          to: '',
        },
        {
          from: 'item.uiMRefStoreitemSaleItem.vendorCd',
          to: '',
        },
        {
          from: 'item.quantity',
          to: '',
        },
        {
          from: 'saleAmount1w',
          to: '',
        },
        {
          from: 'discardAmount1w',
          to: '',
        },
        {
          from: 'discardRate1w',
          to: '',
        },
        {
          from: 'saleNum1wBefore',
          to: '',
        },
        {
          from: 'saleNum2wBefore',
          to: '',
        },
        {
          from: 'saleNum3wBefore',
          to: '',
        },
        {
          from: 'saleNum3w',
          to: '',
        },
        {
          from: 'profitRate3w',
          to: '',
        },
        {
          from: 'price',
          to: '',
        },
        {
          from: 'cost',
          to: '',
        },
        {
          from: 'profitRate',
          to: '',
        },
        {
          from: 'moq',
          to: '',
        },
        {
          from: 'varietyGroupId',
          to: '',
        },
        {
          from: 'varietyGroupIdName',
          to: '',
        },
        {
          from: 'absoluteOrderFlg',
          to: '',
        },
        {
          from: 'varietyPriority',
          to: '',
        },
        {
          from: 'r1StoreinDowPattern.storeinDowPatternDisp',
          to: '',
        },
        {
          from: 'r1StoreinDowPatternIdName',
          to: '',
        },
        {
          from: 'r2StoreinDowPattern.storeinDowPatternDisp',
          to: '',
        },
        {
          from: 'r2StoreinDowPatternIdName',
          to: '',
        },
        {
          from: 'r3StoreinDowPattern.storeinDowPatternDisp',
          to: '',
        },
        {
          from: 'r3StoreinDowPatternIdName',
          to: '',
        },
        {
          from: 'r4StoreinDowPattern.storeinDowPatternDisp',
          to: '',
        },
        {
          from: 'r4StoreinDowPatternIdName',
          to: '',
        },
        {
          from: 'r5StoreinDowPattern.storeinDowPatternDisp',
          to: '',
        },
        {
          from: 'r5StoreinDowPatternIdName',
          to: '',
        },
        {
          from: 'r6StoreinDowPattern.storeinDowPatternDisp',
          to: '',
        },
        {
          from: 'r6StoreinDowPatternIdName',
          to: '',
        },
        {
          from: 'configuredFlg',
          to: '',
        },
        {
          from: 'lastModifiedDatetime',
          to: '',
        },
        {
          from: 'lastModifiedUserName',
          to: '',
        },
        {
          from: 'r1StoreinDowPatternId',
          to: '',
        },
        {
          from: 'r2StoreinDowPatternId',
          to: '',
        },
        {
          from: 'r3StoreinDowPatternId',
          to: '',
        },
        {
          from: 'r4StoreinDowPatternId',
          to: '',
        },
        {
          from: 'r5StoreinDowPatternId',
          to: '',
        },
        {
          from: 'r6StoreinDowPatternId',
          to: '',
        },
      ]
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
